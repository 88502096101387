import { makeVar } from '@apollo/client';

const MyProfileVarInitialState = {
  isShakeAndSharePermitted: false,
  isSmsPermitted: false,
  isEmailPermitted: false,
  isPhoneCallPermitted: false,
  isPushNotificationPermitted: false,
};

const selectedAddressInitialState = {
  addressID: '',
  address: {},
  addressList: [],
};

const basketInfoInitialState = {
  itemAmount: 0,
  brandName: '',
  restaurantName: '',
  restaurantId: null,
  totalPrice: 0,
};

const userCoordinatesInitialState = {
  lat: '',
  lng: '',
};

const merchant = makeVar({ merchant: '0' });
const MyProfileVar = makeVar(MyProfileVarInitialState);
const selectedAddress = makeVar(selectedAddressInitialState);
const basketInfo = makeVar(basketInfoInitialState);
const userCoordinates = makeVar(userCoordinatesInitialState);
const brands = makeVar([]);

const LocalData = {
  MyProfileVar,
  selectedAddress,
  basketInfo,
  merchant,
  userCoordinates,
  brands,
};

export function ResetMerchant() {
  LocalData.merchant({ merchant: '0' });
}

export function ResetLocalDataState() {
  LocalData.MyProfileVar(MyProfileVarInitialState);
  LocalData.selectedAddress(selectedAddressInitialState);
  LocalData.basketInfo(basketInfoInitialState);
  LocalData.userCoordinates(userCoordinatesInitialState);
}

export default LocalData;
