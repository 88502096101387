/* eslint-disable no-restricted-globals */
import firebase from 'firebase';
import { isArray } from 'lodash';
import { brandIdToShortNameConverter } from './brandIdToNameConverter';
import { getFirebaseAnalytics } from './request';

export const analyticsStartUsingView = () => getFirebaseAnalytics().logEvent('start_using_view', {});

export const analyticsBrandSelectionView = (availableBrands) => {
  const brandNames = [];
  const brandStatuses = [];
  if (availableBrands?.length > 0) {
    availableBrands.forEach((brand) => {
      const shortName = brandIdToShortNameConverter(brand?.node?.CRMId);
      const brandStatus = 'Op';
      if (shortName && !brandNames.includes(shortName)) {
        brandNames.push(shortName);
        brandStatuses.push(brandStatus);
      }
    });
    try {
      getFirebaseAnalytics().logEvent('brand_selection_view', {
        brand_name: brandNames.join('|'),
        status: brandStatuses.join('|'),
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const analyticsBrandSelected = (name) => {
  try {
    getFirebaseAnalytics().logEvent('brand_selected', {
      brand_name: name,
    });
  } catch (error) {
    console.log(error);
  }
};

export const analyticsViewPromotion = ({ currentItem, page }) => {
  let id = currentItem?.id || currentItem?.Id || '';

  if (typeof id === 'string') {
    id = id.replace('Landing-page_', '');
    id = Number(id) || id;
  }

  const params = {
    promotion_id: id,
    promotion_name: currentItem?.Title || currentItem?.title,
    creative_name: currentItem?.Banner?.[0]?.url || currentItem?.image?.url,
    creative_slot: currentItem?.Slug || currentItem?.slug,
    location_id: page,
  };
  getFirebaseAnalytics().logEvent('view_promotion', params);
};

export const analyticsSelectPromotion = ({ item, page }) => {
  let id = item?.id || item?.Id || '';

  if (typeof id === 'string') {
    id = id.replace('Landing-page_', '');
    id = Number(id) || id;
  }

  const params = {
    promotion_id: id,
    promotion_name: item?.Title || item?.title,
    creative_name: item?.Banner?.[0]?.url || item?.image?.url,
    creative_slot: item?.Slug || item?.slug,
    location_id: page,
  };

  getFirebaseAnalytics().logEvent('select_promotion', params);
};

export const analyticsViewItemList = (item) => {
  const itemsArray = [];
  /* eslint-disable no-unused-vars */
  const items = item?.items?.forEach((productItem) => {
    if (isArray(productItem)) {
      productItem.forEach((pItem) => {
        if (pItem?.value) {
          itemsArray.push({
            item_brand: item.brandName || '',
            item_id: pItem?.value || '',
            item_name: pItem?.name || '',
            price: pItem?.price || 0,
            quantity: pItem?.quantity || 0,
          });
        }
      });
    }
    if (productItem?.value) {
      itemsArray.push({
        item_brand: item.brandName || '',
        item_id: productItem?.value || '',
        item_name: productItem?.name || '',
        price: productItem?.price || 0,
        quantity: productItem?.quantity || 0,
      });
    }
  });
  try {
    getFirebaseAnalytics().logEvent('view_item_list', {
      item_list_id: item.listId,
      item_list_name: item.listName,
      items: itemsArray,
    });
  } catch (error) {
    console.log(error);
  }
};

export const analyticsSelectItem = (data, list) => {
  try {
    getFirebaseAnalytics().logEvent('select_item', {
      item_list_id: list?.id,
      item_name: data?.name,
      item_list_name: list?.name,
      items: [
        {
          item_name: data?.name || '',
          item_id: data?.id || '',
          item_brand: data?.brand || '',
          price: data?.price || 0,
        },
      ],
    });
  } catch (error) {
    console.log(error);
  }
};

export const analyticsViewItem = (data) => {
  try {
    getFirebaseAnalytics().logEvent('view_item', {
      items: [
        {
          item_name: data?.name || '',
          item_id: data?.id || '',
          item_brand: data?.brand || '',
          price: data?.price || '',
        },
      ],
      item_name: data?.name || '',
      currency: 'TRY',
      value: data?.price || '',
    });
  } catch (error) {
    console.log(error);
  }
};

export const analtyicsAddToCart = (item) => {
  getFirebaseAnalytics().logEvent(firebase.analytics.EventName.ADD_TO_CART, {
    items: [
      {
        item_brand: item?.brand || '',
        item_id: item?.id || '',
        item_name: item?.name || '',
        price: item?.price || 0,
        quantity: item.quantity || 1,
      },
    ],
    currency: 'TRY',
    value: item?.price || 0,
  });
};

export const analtyicsStartUsingClick = (item) => {
  getFirebaseAnalytics().logEvent('start_using_click', {});
};

export const analyticsMenuTap = ({ screen, element, basketValue }) => {
  getFirebaseAnalytics().logEvent('menu_tap', {
    firebase_screen: screen,
    element,
    current_basket_value: basketValue,
  });
};

export const analyticsFooterClick = ({ category, element, path }) => {
  getFirebaseAnalytics().logEvent('footer_click', {
    category,
    element,
    page_path: path,
  });
};

export const analyticsCampaignsFilter = (filterValue) => {
  const {
    minMax: { min, max },
    brandIds,
  } = filterValue;

  const shortNames = brandIds.map((brand) => brandIdToShortNameConverter(brand)).join('|');

  getFirebaseAnalytics().logEvent('filter_click', {
    min,
    max,
    filtered_restaurants: shortNames,
  });
};
