const BURGERKING = {
  id: '354d0e4f-c4f5-e211-bc3c-005056af03f4',
  label: 'Burger King ®',
  shortName: 'BK',
};
const POPEYES = {
  id: '394d0e4f-c4f5-e211-bc3c-005056af03f4',
  label: 'Popeyes ®',
  shortName: 'PP',
};
const ARBYS = {
  id: '3b4d0e4f-c4f5-e211-bc3c-005056af03f4',
  label: 'Arbys ®',
  shortName: 'AR',
};
const SBARRO = {
  id: '374d0e4f-c4f5-e211-bc3c-005056af03f4',
  label: 'Sbarro ®',
  shortName: 'SB',
};
const USTADONERCI = {
  id: '3f4d0e4f-c4f5-e211-bc3c-005056af03f4',
  label: 'Usta Dönerci ®',
  shortName: 'UD',
};
const USTAPIDECI = {
  id: 'ea553690-695b-e911-941a-0050568045bb',
  label: 'Usta Pideci ®',
  shortName: 'UP',
};
const AMASYAET = {
  id: '46d3187d-80aa-e711-80d2-0050568e5e5d',
  label: 'Amasya Et Ürünleri ®',
  shortName: 'AE',
};
const AMASYAET2 = {
  id: '5edb25cd-05be-e711-9419-0050568045bb',
  label: 'Amasya Et Ürünleri ®',
  shortName: 'AE',
};

const SUBWAY = { id: 'd3b491db-de22-ed11-9413-0050568036f6', label: 'Subway®', shortName: 'SW' };

const Brands = [BURGERKING, POPEYES, ARBYS, SBARRO, USTADONERCI, USTAPIDECI, AMASYAET, AMASYAET2, SUBWAY];

export function brandIdToNameConverter(brandId) {
  if (!brandId) return '';
  const brand = Brands.find((element) => element.id === brandId.toString());
  return brand?.label || '';
}

export function brandIdToShortNameConverter(brandId) {
  if (!brandId) return '';
  const brand = Brands.find((element) => element.id === brandId.toString());
  return brand?.shortName || '';
}
