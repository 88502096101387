/* eslint-disable no-unused-expressions */
/* eslint-disable global-require */
/* eslint-disable no-console */
/* eslint-disable camelcase */
// /* global __DEV__ */
import axios from 'axios';
import LocalData from '../apollo/LocalData';

// TODO get this config from env
const firebaseConfig = {
  apiKey: 'AIzaSyCyVVx64JqNEEfveqrSmdJuGM_J9tnCQKk',
  authDomain: 'tikla-gelsin-tab-gida.firebaseapp.com',
  databaseURL: 'https://tikla-gelsin-tab-gida.firebaseio.com',
  projectId: 'tikla-gelsin-tab-gida',
  storageBucket: 'tikla-gelsin-tab-gida.appspot.com',
  messagingSenderId: '416054009369',
  appId: '1:416054009369:web:af21f0caea387190cb9488',
  measurementId: 'G-S133ZS02MY',
};

let instance;
export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    const firebase = require('firebase');
    instance = firebase.default.initializeApp(firebaseConfig);
    instance.auth().languageCode = 'tr';
    return instance;
  }
  return null;
}

export function getFirebaseAnalytics() {
  const analytics = getFirebase();
  if (!analytics) {
    return new Promise((resolve) => resolve(undefined));
  }

  return analytics.analytics();
}

const DeviceType = {
  code: 3,
};

// if (__DEV__) {
//   axios.interceptors.request.use(logFulfilledReq, logRejectedReq);
//   axios.interceptors.response.use(logFulfilledRes, logRejectedRes);
// }

export const requestWithoutToken = (url, options) => {
  const ldm = LocalData.merchant;
  const { merchant } = ldm && ldm();
  return axios({
    method: options.method,
    url,
    headers: {
      'x-merchant-type': merchant,
      'x-device-type': DeviceType.code,
      ...(options.headers || {}),
    },
    data: options.body,
  });
};

export function request(url, options) {
  const headers = options.headers || {};
  const noAuth = headers && headers['x-no-auth'];
  const ldm = LocalData.merchant;
  const { merchant } = ldm && ldm();
  if (noAuth) return requestWithoutToken(url, options);
  const fb = getFirebase();
  if (!fb) {
    return new Promise((resolve) => resolve(undefined));
  }

  return new Promise((resolve) => {
    const withTokenCallback = (accessToken) => {
      // eslint-disable-next-line camelcase
      // const { access_token } = accessToken || {};
      // eslint-disable-next-line camelcase
      const auth = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
      resolve(
        axios({
          method: options.method,
          url,
          headers: {
            'x-merchant-type': merchant,
            'x-device-type': DeviceType.code,
            ...auth,
            ...headers,
          },
          data: options.body,
        }),
      );
    };

    const unsubscribe = getFirebase()
      .auth()
      .onAuthStateChanged((firebaseUser) => {
        // eslint-disable-next-line no-unused-expressions
        unsubscribe && unsubscribe();
        firebaseUser &&
          firebaseUser
            .getIdTokenResult(true)
            // eslint-disable-next-line no-unused-vars
            .then(({ expirationTime, token }) => {
              withTokenCallback(token);
            })
            .catch((error) => {
              console.error(error);
            });
      });
  });
}

let lastBannerTime = -1;

// eslint-disable-next-line consistent-return
export function redirectToApp() {
  const fb = getFirebase();
  if (!fb) {
    return new Promise((resolve) => resolve(undefined));
  }

  const unsubscribe = getFirebase()
    .auth()
    .onAuthStateChanged((firebaseUser) => {
      unsubscribe && unsubscribe();
      firebaseUser &&
        firebaseUser
          .getIdTokenResult(true)
          .then(() => {
            window.location.href = '/a/';
          })
          .catch((error) => {
            console.error(error);
          });
    });
}

function checkErrorStatusCode(error) {
  if (error?.statusCode === 401 || error?.statusCode === 403) {
    return true;
  }

  const servicesDownMessage = 'One or more errors occurred. (The request channel timed out attempting to send after';
  if (error.message?.includes) {
    if (error.message.includes(servicesDownMessage)) return true;
  }

  const showServerErrorBannerConditions = ['550', 'INTERNAL_SERVER_ERROR'];
  const isStatusCodeDirty = showServerErrorBannerConditions.includes(error.statusCode?.toString()) || false;
  return isStatusCodeDirty;
}

export function apolloRequest(url, options) {
  const onfulfilled = (response) => {
    const errors = response?.data?.errors;

    if (errors && errors.length && errors.find((error) => checkErrorStatusCode(error))) {
      const date = new Date().getTime();
      if (date - lastBannerTime > 4000) {
        console.error('SERVICES UNAVAILABLE');
      }
      lastBannerTime = date;
    }

    const responseBody = typeof response.data === 'object' ? JSON.stringify(response.data) : response.data;

    return new Response(responseBody, {
      status: response.status,
      statusText: response.statusText,
      headers: response.headers,
    });
  };

  const onrejected = (err) => {
    // logRejectedApolloRes(reason);
    console.error(err);
    throw err;
  };

  return request(url, options).then(onfulfilled).catch(onrejected);
}

function makeRequest(url, options, ignoreError = false) {
  const onfulfilled = (response) => (response.status === 204 || response.status === 205 ? null : response?.data);

  const onrejected = (err) => {
    if (ignoreError) return err;
    throw err;
  };

  return request(url, options).then(onfulfilled, onrejected);
}

export function post(url, body, ignoreError = true, raw = false) {
  const bodySanitized = body || {};
  const bodyConverted = raw ? bodySanitized : JSON.stringify(bodySanitized);
  const options = {
    method: 'POST',
    bodyConverted,
    headers: { 'Content-Type': 'application/json' },
  };
  return makeRequest(url, options, ignoreError);
}

export function get(url, ignoreError = true) {
  const options = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  return makeRequest(url, options, ignoreError);
}
